<template>
<!-- Hero -->
<div class="fixed  bottom-0 right-0 p-4 z-20 hover:opacity-75 opacity-100">
    <span class="relative flex h-3 w-3 ml-2">
    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75"></span>
    <span class="relative inline-flex rounded-full h-3 w-3 bg-green-300"></span>
  </span>
  <a href="https://api.whatsapp.com/send/?phone=551930941248&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20sistemas%20white%20label" target='_blank' class="bg-green-500 text-xl shadow-lg px-5 text-white rounded p-2">Fale com nossa equipe</a>
</div>

<header class="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-white border-b border-gray-200 text-sm py-3 sm:py-0">
  <nav class="relative max-w-7xl flex flex-wrap basis-full items-center w-full mx-auto px-4 sm:flex sm:items-center justify-between sm:px-6 lg:px-8" aria-label="Global">
    <div class="flex items-center justify-between">
      <a class="flex-none text-3xl p-8" href="#" aria-label="Brand">seu<span class="font-bold">saas</span></a>
    </div>
    <div class="text-gray-300 mr-12">
      <a href="https://instagram.com/seusaasonline" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" class="currentColor" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
      </a>
    </div>
  </nav>
</header>

<!-- End Offcanvas -->
<div class="relative mt-5 overflow-hidden">
  <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-24">
    <div class="text-center">
      <h1 class="text-4xl sm:text-6xl font-bold text-gray-800">
        Revenda com a sua marca
      </h1>

      <p class="mt-3 text-gray-600">
        Software para você empreender!
      </p>

      <div class="mx-auto max-w-xl relative">
        <!-- Form -- -->
        <!-- End Form -->

        <!-- SVG Element -->
        <div class="absolute top-0 right-0 -translate-y-12">
          <svg class="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
            <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
            <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
          </svg>
        </div>
        <!-- End SVG Element -->

        <!-- SVG Element -->
        <div class="absolute bottom-0 left-0 translate-y-10">
          <svg class="w-40 h-auto text-cyan-500" width="347" height="188" viewBox="0 0 347 188" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426" stroke="currentColor" stroke-width="7" stroke-linecap="round"/>
          </svg>
        </div>
        <!-- End SVG Element -->
      </div>
    </div>
  </div>
</div>

<!-- Clients -->
<div class="max-w-[85rem] mb-5 lg:mb-24 px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <!-- Title -->
  <div class="w-2/3 sm:w-1/2 lg:w-1/3 mx-auto text-center mb-6">
    <h2 class="text-gray-600 dark:text-gray-400">Powered by</h2>
  </div>
  <!-- End Title -->

  <div class="flex justify-center gap-x-6 sm:gap-x-12 lg:gap-x-24">
   <a href="https://avanso.com.br" target='_blank'><img src="https://avanso.com.br/images/download.webp" class="invert opacity-75 w-32" /></a>
</div>
</div>
<!-- End Clients -->


<!-- Card Blog -->
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <!-- Grid -->
  <div class="grid sm:grid-cols-2 sm:items-center gap-8">
    <div class="sm:order-2">
      <div class="relative rounded-lg">
        <img src="@/assets/screen.png" class="shadow-lg border rounded" />
      </div>
    </div>
    <!-- End Col -->

    <div class="sm:order-1">
      <p class="mb-5 inline-flex items-center gap-1.5 py-1.5 px-3 rounded-md text-xs font-medium bg-gray-100 text-gray-800">
        White Label
      </p>

      <h2 class="text-2xl font-bold md:text-3xl lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight text-gray-800">
        <span>
          ChatGPT + WhatsApp para Pré Atendimento
        </span>
      </h2>

      <!-- Avatar -->
      <div class="mt-6 sm:mt-10 flex items-center">

        <div>
          <p class="sm:mb-1 font-semibold text-gray-800">
            Revenda com a sua marca!
          </p>
          <p class="text-xs text-gray-500">
            A partir de R$150/mês para até 5 usuários no plano trimestral.
          </p>
        </div>
      </div>
      <!-- End Avatar -->
      <div class="mt-8">
        <a class="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="https://www.youtube.com/watch?v=mHqIoQr0rhk" target="_blank">
          Vídeo demonstrativo
          <svg class="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </a>
      </div>

      <div class="mt-2">
        <a class="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="https://dashboard.gochatbot.com.br" target="_blank">
          Testar grátis
          <svg class="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </a>
      </div>
    </div>
    <!-- End Col -->
  </div>
  <!-- End Grid -->
</div>

<!-- Subscribe -->
<div class="max-w-6xl mt-24 py-10 px-4 sm:px-6 lg:px-8 lg:py-16 mx-auto">
  <div class="max-w-xl text-center mx-auto">
    <div class="mb-5">
      <h2 class="text-sm text-gray-400 md:leading-tight">Em breve novos SAAS white label...</h2>
    </div>
  </div>
</div>

</template>

<script>

export default {
  name: 'App'

}
</script>

<style>
</style>
